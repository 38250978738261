import * as React from 'react'
import { window } from 'browser-monads';
import YouTube from 'react-youtube';
// import YoutubeBackground from 'react-youtube-background'
import FizzyButton from '../fizzyButton/fizzyButton'
import * as styles from './videobackground.module.css'
import video from '../../video/hero_pinkPhase.mp4'

const playerOptions = {
    controls: 0,
    modestbranding: 1,
    disablekb: 1,
    showinfo: 0
}

function VideoHero() {
    return (
        <div id={styles.mainContainer}>
            <video autoPlay muted loop id={styles.myVideo} alt="pink phase background video">
                <source src={video} type="video/mp4" />
            </video>

            <div className={styles.content}>
                <h1 id={styles.ctaText}>Do More Sales <br/>With Less Work</h1>
                <FizzyButton secondary={"meh"}>Let's Get Started</FizzyButton>
            </div>
        </div>
    )
}

export default VideoHero