import * as React from 'react'
import FizzyButton from '../fizzyButton/fizzyButton'
import * as styles from './buttonDivWidget.module.css'

function ButtonDivWidget( {text, cta} ) {
    return (
        <div className={styles.buttonDivWidget_container}>
            <h1>{cta}</h1>
            <FizzyButton>
                {text}
            </FizzyButton>
        </div>
    )
}

export default ButtonDivWidget